import { ref } from 'vue'

type tplotOptions = {
  [key: string]: any
}
interface IRule {
  key: string
  value: any
  message: string
  validate(): boolean
  setMessage(message: string): void
}
export class Form {
  public reminds = {} as tplotOptions
  public ruleNames: IRule[]

  constructor(ruleNames: IRule[]) {
    this.ruleNames = ruleNames
  }

  validate() {
    this.reminds = {}
    this.ruleNames.forEach((ruleName: IRule) => {
      const isPass = ruleName.validate()
      if (!isPass) {
        this.recordRemind(ruleName.key, ruleName.message)
      }
    })
  }

  recordRemind(key: string, msg: string) {
    const hasKey = Object.prototype.hasOwnProperty.call(
      this.reminds,
      key
    )
    if (hasKey) return
    this.reminds[key] = msg
  }

  inValid() {
    return Object.keys(this.reminds).length > 0
  }
}
export class RequiredRule implements IRule {
  public key: string
  public value: string
  public message: string

  constructor(key: string, value: string, message?: string) {
    this.key = key
    this.value = value
    this.message = message || '必填欄位，不得為空白。'
  }

  validate() {
    return this.value.trim().length !== 0
  }

  setMessage(message: string) {
    this.message = message
  }
}
export class PwdFormatRule implements IRule {
  public key: string
  public value: string
  public message: string

  constructor(key: string, value: string, message?: string) {
    this.key = key
    this.value = value
    this.message = message || '請輸入8～12個字元英數字，區分大小寫，至少需含一個英文。'
  }

  validate() {
    const drgx = /^(?=.*[A-Za-z])[A-Za-z\d]{8,12}$/
    return drgx.test(this.value)
  }

  setMessage(message: string) {
    this.message = message
  }
}
export class EqualToRule implements IRule {
  public key: string
  public value: string
  public compareValue: string
  public message: string

  constructor(key: string, value: string, compareValue: string, message?: string) {
    this.key = key
    this.value = value
    this.compareValue = compareValue
    this.message = message || '輸入內容不相同'
  }

  validate() {
    return this.value === this.compareValue
  }

  setMessage(message: string) {
    this.message = message
  }
}
export class EmailRule implements IRule {
  public key: string
  public value: string
  public message: string

  constructor(key: string, value: string, message?: string) {
    this.key = key
    this.value = value
    this.message = message || 'E-mail 格式不正確。'
  }

  validate() {
    const drgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return drgx.test(this.value)
  }

  setMessage(message: string) {
    this.message = message
  }
}
export class VertifiedCodeRule implements IRule {
  public key: string
  public value: string
  public message: string

  constructor(key: string, value: string, message?: string) {
    this.key = key
    this.value = value
    this.message = message || '驗證碼只能輸入6碼數字。'
  }

  validate() {
    const drgx = /^\d{6}$/
    return drgx.test(this.value)
  }

  setMessage(message: string) {
    this.message = message
  }
}
