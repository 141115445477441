
import { defineComponent, ref } from 'vue'
import axios from '@/http'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'GoogleSignInButton',
  setup() {
    const store = useStore()
    const url = ref('' as string)

    function login() {
      new Promise((resolve, reject) => {
        axios
          .get('/googleLogin')
          .then((res) => {
            url.value = res.data.url
            const checkToken = res.data.checkToken
            store.dispatch('setCheckToken', checkToken)
            resolve('work')
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      }).then(() => {
        location.href = url.value
      })
    }

    return {
      login
    }
  }
})
