
import { computed, defineComponent, inject, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { modalInstance } from '@/type'
import modal from '@/views/components/modal.vue'
export default defineComponent({
  components: { modal },
  setup() {
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const userInfo = computed(() => store.getters.userInfo)

    onBeforeMount(() => {
      const token = route.query.token ?? ''

      if (Object.keys(userInfo.value).length) {
        store.dispatch('authLogout').then(() => {
          store
            .dispatch('authLoginId', { token })
            .then(() => {
              store.dispatch('initSetup').then(() => {
                router.push({
                  name: 'index'
                })
              })
            })
            .catch((error) => {
              modal({
                classType: 'error',
                cancelBtn: false,
                closeAble: false,
                content: `登入失敗 ${error.response.errors.detail}`,
                confirmBtnText: '確定',
                confirmBtnClick: () => {
                  return new Promise((resolve, reject) => {
                    resolve('work')
                    router.push({
                      name: 'index'
                    })
                  })
                }
              }).show()
            })
        })
      } else {
        store
          .dispatch('authLoginId', { token })
          .then(() => {
            store.dispatch('initSetup').then(() => {
              router.push({
                name: 'index'
              })
            })
          })
          .catch((error) => {
            modal({
              classType: 'error',
              cancelBtn: false,
              closeAble: false,
              content: `登入失敗 ${error.response.errors.detail}`,
              confirmBtnText: '確定',
              confirmBtnClick: () => {
                return new Promise((resolve, reject) => {
                  resolve('work')
                  router.push({
                    name: 'index'
                  })
                })
              }
            }).show()
          })
      }
    })

    return {}
  }
})
