
import { defineComponent, inject, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { modalInstance } from '@/type'
import modal from '@/views/components/modal.vue'
import axios from '@/http'
import fileRequest from '@/http/file'
export default defineComponent({
  components: { modal },
  setup() {
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const fileType = ref('')
    const filePrefix = ref('') as any
    const deadTime = ref('')
    const show = ref(false)
    const password = ref(null)
    const { storeId, type, zipFilePrefix } = route.query

    onBeforeMount(() => {
      filePrefix.value = zipFilePrefix
      axios
        .get(`/getDownloadInfo/${storeId}/${type}/${zipFilePrefix}`)
        .then((res) => {
          const data = res.data
          fileType.value = data.fileType
          deadTime.value = data.deadTime
          show.value = true
        })
        .catch((error) => {
          modal({
            classType: 'error',
            cancelBtn: false,
            confirmBtn: false,
            content: `${error.response.errors.detail}`,
            closeAble: false,
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                resolve('work')
              })
            }
          }).show()
        })
    })

    function download() {
      fileRequest
        .get(`/getDownload/${storeId}/${type}/${zipFilePrefix}`, {
          responseType: 'blob',
          params: {
            password: password.value
          }
        })
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]))
          var fURL = document.createElement('a')
          fURL.href = fileURL
          fURL.setAttribute('download', `${zipFilePrefix}.zip`)
          document.body.appendChild(fURL)
          fURL.click()
          return true
        })
        .catch((error) => {
          error.response.data.text().then((text: any) => {
            const errorMsg = JSON.parse(text).errors.detail
            modal({
              classType: 'error',
              confirmBtn: true,
              cancelBtn: false,
              content: errorMsg,
              closeAble: true,
              confirmBtnClick: () => {
                return new Promise((resolve, reject) => {
                  resolve('work')
                })
              }
            }).show()
          })
        })
    }

    function inputPassword(event: any) {
      let value = event.target.value
      if (value.length > 4) {
        value = value.slice(0, 4)
      }
      password.value = value
    }

    return {
      fileType,
      deadTime,
      filePrefix,
      show,
      download,
      password,
      inputPassword
    }
  }
})
