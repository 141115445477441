
import {
  defineComponent,
  reactive,
  ref,
  computed,
  inject,
  onBeforeMount
} from 'vue'
import ForgetPwdLightbox from '@/views/components/forgetPwdLightbox.vue'
import modal from '@/views/components/modal.vue'
import PasswordInput from '@/views/components/passwordInput.vue'
import { modalInstance } from '@/type'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from '@/http'

export default defineComponent({
  components: {
    ForgetPwdLightbox,
    PasswordInput,
    modal
  },
  setup() {
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const userInfo = computed(() => store.getters.userInfo)

    const hasVertifiedCode = computed(
      () => userInfo.value.google2faSecret !== null
    )

    const secret = ref(null)
    const google2faUrl = ref(null)

    onBeforeMount(async () => {
      if (!Object.keys(userInfo.value).length) {
        await router.push({
          name: 'login'
        })
      }

      if (!userInfo.value.needToGoogle2fa) {
        await router.push({
          name: 'index'
        })
      }

      if (!hasVertifiedCode.value) {
        await axios
          .post('/google2fa', {
            account: userInfo.value.account
          })
          .then((res) => {
            const data = res.data
            google2faUrl.value = data.google2fa_url
            secret.value = data.secret
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
    const forgetPwdRef = ref()

    // 輸入驗證碼
    const vertifiedCodeInfo = reactive({
      code: ref('')
    })

    // 驗證驗證碼表單
    const submitCodeErrorMessage = ref(null)

    function submitVertifiedCode() {
      axios
        .post('/google2favalidate', {
          code: vertifiedCodeInfo.code,
          secret: userInfo.value.google2faSecret
        })
        .then((res) => {
          // 綁定成功後跳成功視窗
          modal({
            content: '驗證成功',
            classType: 'success',
            cancelBtn: false,
            closeAble: false,
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                store.dispatch('revokedNeedToGoogle2fa').then((res) => {
                  router.push({
                    name: 'index'
                  })
                })
                resolve('work')
              })
            }
          }).show()
        })
        .catch((error) => {
          submitCodeErrorMessage.value = error.response.errors.detail
        })
    }

    // 設定驗證碼
    const step = reactive({
      current: 1,
      agreeCheck: {
        step1: false,
        step2: false,
        step3: false
      }
    })

    const confirmPwdInfo = reactive({
      password: ref('')
    })

    function resetConfirmPwd() {
      confirmPwdInfo.password = ''
    }

    function nextStep() {
      step.current += 1
    }

    function backStep() {
      step.current -= 1
    }

    const isSetVertifiedCode = ref(false)

    function setVertifiedCode(isOpen: boolean) {
      isSetVertifiedCode.value = isOpen
    }

    const bindCodeErrorMessage = ref(null)

    function bindVertifiedCode() {
      axios
        .post('/reset2fa', {
          account: userInfo.value.account,
          password: confirmPwdInfo.password,
          secret: secret.value
        })
        .then((res) => {
          // 綁定成功後跳成功視窗
          confirmPwdInfo.password = ''
          modal({
            content: '綁定成功',
            classType: 'success',
            cancelBtn: false,
            closeAble: false,
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                store.dispatch('revokedNeedToGoogle2fa').then((res) => {
                  router.go(0)
                })
                resolve('work')
              })
            }
          }).show()
        })
        .catch((error) => {
          bindCodeErrorMessage.value = error.response.errors.detail
        })
    }

    function logout() {
      store.dispatch('authLogout').then(() => {
        router.push({
          name: 'login'
        })
      })
    }

    return {
      nextStep,
      backStep,
      setVertifiedCode,
      bindVertifiedCode,
      resetConfirmPwd,
      confirmPwdInfo,
      hasVertifiedCode,
      vertifiedCodeInfo,
      submitVertifiedCode,
      logout,
      google2faUrl,
      secret,
      isSetVertifiedCode,
      step,
      bindCodeErrorMessage,
      submitCodeErrorMessage,
      forgetPwdRef
    }
  }
})
