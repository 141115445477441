
import { defineComponent, ref, reactive, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import modal from '@/views/components/modal.vue'
import PasswordInput from '@/views/components/passwordInput.vue'
import GoogleSignInButton from '@/views/components/googleSigninButton.vue'
import ForgetPwdLightbox from '@/views/components/forgetPwdLightbox.vue'
import { VueRecaptcha } from 'vue-recaptcha'

import axios from '@/http'
declare const window: any

interface IError {
  code: string
  detail: string
  source: string
}

export default defineComponent({
  components: {
    GoogleSignInButton,
    modal,
    PasswordInput,
    ForgetPwdLightbox,
    VueRecaptcha
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const recaptcha = computed(() => process.env.VUE_APP_RECAPTCHA)

    const forgetPwdRef = ref()

    // robot ecaptcha
    const recaptchaRef = ref(null as any)
    const isNotRobot = ref(false)

    const siteKey = ref('')

    const resetMailErrorMessage = ref(null)

    function onCaptchaVertified(token: string) {
      isNotRobot.value = true
      form.recaptchaToken = token
    }

    function onCaptchaExpired() {
      isNotRobot.value = false
      form.recaptchaToken = ''
    }

    // 登入表單
    const form = reactive({
      account: ref(''),
      password: ref(''),
      recaptchaToken: ref('')
    })

    const errors = reactive<IError>({} as IError)

    // 登入按鈕disable狀態
    const loginButtonDisabled = ref(true)
    watch(form, () => {
      if (
        form.account.trim() === '' ||
        form.password.trim() === '' ||
        (recaptcha.value === 'true' && !isNotRobot.value)
      ) {
        loginButtonDisabled.value = true
      } else {
        loginButtonDisabled.value = false
      }
    })

    function submit() {
      store
        .dispatch('authLogin', form)
        .then(() => {
          store.dispatch('initSetup').then(() => {
            router.push({
              name: 'index'
            })
          })
        })
        .catch((error) => {
          Object.assign(errors, error.response.errors)
          isNotRobot.value = false
          form.recaptchaToken = ''
          recaptchaRef.value.reset()
        })
    }

    axios
      .get('/recaptcha')
      .then((res) => {
        const data = res.data
        siteKey.value = data.siteKey
      })
      .catch((err) => {
        console.log(err)
      })

    return {
      form,
      errors,
      submit,
      onCaptchaVertified,
      onCaptchaExpired,
      siteKey,
      resetMailErrorMessage,
      loginButtonDisabled,
      forgetPwdRef,
      recaptchaRef
    }
  }
})
