
import { computed, defineComponent, inject, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { modalInstance } from '@/type'
import modal from '@/views/components/modal.vue'
export default defineComponent({
  components: { modal },
  setup() {
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const checkToken = computed(() => store.getters.checkToken).value

    onBeforeMount(() => {
      const code = route.query.code
      const state = route.query.state
      store
        .dispatch('googleLoginCallback', { code, state, checkToken })
        .then(() => {
          router.push({
            name: 'google2Fa'
          })
        })
        .catch((error) => {
          modal({
            classType: 'error',
            cancelBtn: false,
            content: `登入失敗 ${error.response.errors.detail}`,
            confirmBtnText: '確定',
            confirmBtnClick: () => {
              return new Promise((resolve, reject) => {
                resolve('work')
                router.push({
                  name: 'index'
                })
              })
            }
          }).show()
        })
    })

    return {}
  }
})
