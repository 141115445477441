
import { defineComponent, reactive, ref } from 'vue'
import {
  Form,
  RequiredRule,
  PwdFormatRule,
  EqualToRule
} from '@/plugins/formChecker'
import PasswordInput from '@/views/components/passwordInput.vue'

export default defineComponent({
  components: {
    PasswordInput
  },
  setup() {
    const resetPwdInfo = reactive({
      password: ref(''),
      confirmPassword: ref('')
    })

    const isReset = ref(false)

    // 驗證重設密碼表單
    const pwdRequire = new RequiredRule('password', resetPwdInfo.password)
    const pwdFormat = new PwdFormatRule('password', resetPwdInfo.password)

    const confirmPwdRequire = new RequiredRule(
      'confirmPassword',
      resetPwdInfo.confirmPassword
    )

    const confirmPwdEqualTo = new EqualToRule(
      'confirmPassword',
      resetPwdInfo.confirmPassword,
      resetPwdInfo.password,
      '您所輸入的密碼不相同。'
    )

    function updateResetPwd() {
      pwdRequire.value = resetPwdInfo.password
      pwdFormat.value = resetPwdInfo.password
      confirmPwdRequire.value = resetPwdInfo.confirmPassword
      confirmPwdEqualTo.value = resetPwdInfo.confirmPassword
      confirmPwdEqualTo.compareValue = resetPwdInfo.password
    }

    const resetPwdForm = ref(
      new Form([pwdRequire, pwdFormat, confirmPwdRequire, confirmPwdEqualTo])
    )

    function submitResetPwd() {
      updateResetPwd()
      resetPwdForm.value.validate()
      if (resetPwdForm.value.inValid()) return
      isReset.value = true
    }

    return {
      resetPwdInfo,
      isReset,
      submitResetPwd,
      resetPwdForm
    }
  }
})
